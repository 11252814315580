export const partnerType = {
    nhs: 'nhs',
    zumper: 'zumper',
    apartment: 'apartment',
    veterans: 'veterans',
    alliance: 'alliance',
    realtor: 'realtor',
    proplus: 'proplus',
};

export const fallbackPartner = {
    partnerId: 'movoto',
    partnerName: 'Movoto',
    partnerType: 'provider',
    condition: 'fallback',
};

export const getStaticPartnerId = (partner, environment = 'dev') => {
    const partners = {
        nhs: {
            dev: '8ed70a85-279e-4ee3-a19d-adeae39e26ce',
            stage: '81e7809c-0d28-4277-8220-9187d6d138d1',
            prod: '81e7809c-0d28-4277-8220-9187d6d138d1',
        },
        zumper: {
            dev: 'd75c012c-8fb8-49f9-9f7c-c9a49970239b',
            stage: '1f125803-b50c-4054-a486-e2ace27166e2',
            prod: '1f125803-b50c-4054-a486-e2ace27166e2',
        },
        'apartment-list': {
            dev: 'fec4b624-de04-4acc-bd32-3c40d710db4b',
            stage: 'd9d87047-6ccf-470d-b882-adf5cdb68578',
            prod: 'd9d87047-6ccf-470d-b882-adf5cdb68578',
        },
        movoto: {
            dev: '56102ea5-ff59-4059-b3dd-2bdc30d7f189',
            stage: '572e9d28-239c-46ba-9626-1554b974cc98',
            prod: '572e9d28-239c-46ba-9626-1554b974cc98',
        },
        veterans: {
            dev: '740a166f-4b32-4995-963e-442df9d7690d',
            stage: '1333ce0e-6059-4816-b96a-259331520fbe',
            prod: '1333ce0e-6059-4816-b96a-259331520fbe',
        },
    };

    if (partners.hasOwnProperty(partner) && partners[partner].hasOwnProperty(environment)) {
        return partners[partner][environment];
    }
    return partner;
};