const filterMapping = {
    singleFamily: {url: 'single-family'},
    multiFamily: {url: 'multi-family'},
    condo: {url: 'condos'},
    land: {url: 'land'},
    mobile: {url: 'mobile'},
    openHouse: {url: 'open-houses', countObjKey: 'homeOpenCount'},
    priceReduce: {url: 'reduced-30', countObjKey: 'priceReducedCount'},
    virtualTour: {url: 'virtual-tour'},
    newConstruction: {url: 'new-construction', countObjKey: 'newConstructionHomesCount'},
    luxuryHomes: {url: 'luxury-homes'},
    new: {url: 'new-7', countObjKey: 'homeNewCount'},
    foreClosed: {url: 'foreclosed'},
    rentals: {url: 'rentals'},
    attributes: {url: 'att-'},
    'beds-2': {url: 'bed-2-0'},
    'beds-3': {url: 'bed-3-0'},
    'baths-2': {url: 'bath-2-0'},
    'baths-3': {url: 'bath-3-0'}
};

const _getRentalsUrl = function(isRentals, generateFilter) {
    if (isRentals && !generateFilter || !isRentals && generateFilter === 'rentals') {
        return `${filterMapping.rentals.url}/`;
    }
    return '';
}

const _formatText = function(text) {
    return text.replace(/[^a-zA-Z0-9]+/gi, '-').replace(/-*\/$/, '\/').replace(/-$/, '');
};


const _isDallas = function(stateCode, cityName) {
    return cityName && cityName.toUpperCase() === 'DALLAS' && stateCode && stateCode.toUpperCase() === 'TX';
};

const _getMarketTrendsPath = function (geoPaths, geoInfo = {}) {
    let path = '';
    if (geoInfo.geoType == 'NEIGHBORHOOD' && geoPaths.neighborhoodPath) {
        path = geoPaths.neighborhoodPath;
    } else if (geoInfo.geoType == 'ZIPCODE' && geoPaths.zipcodePath) {
        path = geoPaths.zipcodePath;
    } else if (geoInfo.geoType == 'STATE') {
        path = geoPaths.stateCode;
    } else {
        path = geoPaths.cityPath;
    }
    return path ? path + 'market-trends/' : '';
};

const _generateFilterUrl = function(baseFilterUrl, filterArray = [], listingCountObj = {}, rentals) {
    let filteredUrlObjSet = {};
    let filterUrlObject, countObjKey, rentalFilterUrl;

    if (filterArray && filterArray.length){
        filterArray.forEach(filterItem => {
            filterUrlObject = filterMapping[filterItem];
            rentalFilterUrl = _getRentalsUrl(rentals, filterItem);

            if (filterUrlObject) {
                countObjKey = filterUrlObject.countObjKey || `${filterItem}Count`;

                filteredUrlObjSet[filterItem] = `${baseFilterUrl}${filterUrlObject.url}/${filterUrlObject.url !== 'rentals' ? rentalFilterUrl : ''}`;
                filteredUrlObjSet[`${filterItem}Count`] = listingCountObj.hasOwnProperty(countObjKey) && !rentals ? listingCountObj[countObjKey] : 1/*Setting 1 as data not available*/;
            }
        });
    }

    return filteredUrlObjSet;
};
const _getGeoPath = function(geoPaths){
    return geoPaths.zipcodePath ||
        geoPaths.neighborhoodPath ||
        geoPaths.cityPath ||
        geoPaths.countyPath ||
        geoPaths.gpsPath ||
        geoPaths.statePath || '';
};

const urlMixin = {
    methods: {
        generateMapSearchUrl: function (appUrl, {type, stateCode, cityName, zipcode, countyCode, neighborhood, siteMapPropertyPageUrl = '', filterArray = [], listingCountObj = {}, rentals}) {
            let mapSearchPageBaseUrl,
                rentalFilterUrl = _getRentalsUrl(rentals),
                output = {
                    base: '',
                    filteredBaseUrl: '',
                    sold: '',
                    baseCount: rentals ? 1 : listingCountObj[type] && listingCountObj[type].homeForSaleCount
                };

            if (siteMapPropertyPageUrl) {
                mapSearchPageBaseUrl = siteMapPropertyPageUrl.toLowerCase();
            } else {
                switch (type && type.toLowerCase()) {
                    case 'city':
                        mapSearchPageBaseUrl = (cityName && stateCode && `${_formatText(cityName)}-${stateCode}/${rentalFilterUrl}`);
                        break;

                    case 'zipcode':
                        mapSearchPageBaseUrl = stateCode && zipcode && `${stateCode}/${zipcode}/${rentalFilterUrl}`;
                        break;

                    case 'neighborhood':
                        mapSearchPageBaseUrl = cityName && stateCode && neighborhood && `${_formatText(cityName)}-${stateCode}/${_formatText(neighborhood)}/${rentalFilterUrl}`;
                        break;

                    case 'county':
                        mapSearchPageBaseUrl = (countyCode && stateCode && `${_formatText(countyCode)}-${stateCode}/${rentalFilterUrl}`);
                        break;
                    case 'state':
                        mapSearchPageBaseUrl = (stateCode && `${stateCode}/${rentalFilterUrl}`);
                        break;
                }

                mapSearchPageBaseUrl = mapSearchPageBaseUrl && mapSearchPageBaseUrl.toLowerCase();
            }

            if (mapSearchPageBaseUrl) {
                output.base = output.sold = output.filteredBaseUrl = appUrl;

                if(_isDallas(stateCode, cityName) && type && type.toLowerCase() === 'city' && !rentals) {
                    output.base += 'homes-for-sale/';
                }

                output.base += mapSearchPageBaseUrl;
                output.filteredBaseUrl += mapSearchPageBaseUrl;
                output.sold += `${mapSearchPageBaseUrl}${output.sold && output.sold.includes('sold') ? '' : 'sold/' }`;

                if (filterArray && filterArray.length){
                    output.filteredUrlObj = _generateFilterUrl(output.filteredBaseUrl, filterArray, listingCountObj[type], rentals);
                }
            } else {
                output.filteredUrlObj = {};
            }

            return output;
        },
        generateGeoPaths: function(data, {rentals, listingCountObj = {}}) {
            var cityPath = '';
            if(data.cityCode && data.stateCode){
                cityPath = `${data.cityCode}-${data.stateCode}`;
            }
            var geoPaths = {
                neighborhoodPath: data.neighborhoodPath || '',
                neighborhoodPagePath: data.neighborhoodPath ? `neighborhoods/${data.neighborhoodPath}` : '',
                zipcodePath: data.zipcode && data.stateCode ? `${data.stateCode}/${data.zipcode}/` : '',
                cityPath: cityPath ? `${cityPath}/` : '',
                countyPath: data.countyCode ? `${data.countyCode}-${data.stateCode}/` : '',
                statePath: data.stateCode ? `sitemap/${data.stateCode}/` : '',
                schoolPath: cityPath ? `schools/${cityPath}/` : '',
                gpsPath: data.lat && data.lng ? `for-sale/@${data.lat},${data.lng}/` : ''
            };
            if(rentals){
                geoPaths.geoPath = `${_getGeoPath(geoPaths)}${filterMapping.rentals.url}/`;
                geoPaths.gpsPath = data.lat && data.lng ? `${filterMapping.rentals.url}/@${data.lat},${data.lng}/` : `${filterMapping.rentals.url}/`;
                geoPaths.statePath && (geoPaths.statePath += `${filterMapping.rentals.url}/`);
                geoPaths.neighborhoodPath && (geoPaths.neighborhoodPath += `${filterMapping.rentals.url}/`);
                geoPaths.zipcodePath && (geoPaths.zipcodePath += `${filterMapping.rentals.url}/`);
                geoPaths.cityPath && (geoPaths.cityPath += `${filterMapping.rentals.url}/`);
                geoPaths.countyPath && (geoPaths.countyPath += `${filterMapping.rentals.url}/`);
                geoPaths.newPath = cityPath ? `${cityPath}/${filterMapping.rentals.url}/${filterMapping.new.url}/` : '';
                geoPaths.openHousePath = cityPath ? `${cityPath}/${filterMapping.rentals.url}/${filterMapping.openHouse.url}/` : '';
                geoPaths.priceReducePath = cityPath ? `${cityPath}/${filterMapping.rentals.url}/${filterMapping.priceReduce.url}/` : '';
            }else{
                geoPaths.newPath = cityPath ? `${cityPath}/${filterMapping.new.url}/` : '';
                geoPaths.openHousePath = cityPath ? `${cityPath}/${filterMapping.openHouse.url}/` : '';
                geoPaths.priceReducePath = cityPath ? `${cityPath}/${filterMapping.priceReduce.url}/` : '';
                geoPaths.marketTrendsPath = _getMarketTrendsPath(geoPaths, data);
                if(_isDallas(data.stateCode, data.city) && !data.extraPath){
                    geoPaths.cityPath = 'homes-for-sale/' + geoPaths.cityPath;
                }
                geoPaths.geoPath = _getGeoPath(geoPaths) || 'for-sale/';
            }
            return geoPaths;
        },
        getSingleFilterUrl(filter) {
            if(filterMapping[filter]) {
                return `${filterMapping[filter].url}/`;
            } else {
                return '';
            }
        },
        getPopularListInfo: function(geo={}) {
            //need further cleanup of features code across different pages, this is quick fix.
            // if(geo.state==='TX' && (geo.geoType === "CITY") && geo.city && geo.cityCode){
            //     return [{ 'nameForUrl': `${geo.cityCode}-tx/att-fireplace/`, 'displayName' : `${geo.city} Homes With Fireplaces`},
            //     { 'nameForUrl': `${geo.cityCode}-tx/att-backyard/`, 'displayName' : `${geo.city} Homes With Backyards`},
            //     { 'nameForUrl': `${geo.cityCode}-tx/att-view/`, 'displayName' : `${geo.city} Homes With a View`},
            //     { 'nameForUrl': `${geo.cityCode}-tx/att-corner_lot/`, 'displayName' : `${geo.city} Corner Lot Homes`},
            //     { 'nameForUrl': `${geo.cityCode}-tx/att-natural_light/`, 'displayName' : `${geo.city} Homes With Natural Light`}];
            // }
            return [];
        },
        slugifyString: (str) => {
            str = str.replace(/^\s+|\s+$/g, ''); // trim leading/trailing white space
            str = str.toLowerCase(); // convert string to lowercase
            str = str
                .replace(/[^a-z0-9 -]/g, '') // remove any non-alphanumeric characters
                .replace(/\s+/g, '-') // replace spaces with hyphens
                .replace(/-+/g, '-'); // remove consecutive hyphens
            return str;
        }
    }
};

export default urlMixin;
