import getGeo from '../glb/geo';
export default function () {
    return {
        seo: null,
        pageInfo: null,
        geo: getGeo(),
        propertyId: null,
        listingId: null,
        path: null,
        dppUrl: null,
        tnImgPath: null,
        photoCount: 0,
        totalPhotosCount: 0,
        //cate index
        cateIndex: 0,
        //sub index in the cate
        photoIndex: 0,
        //iamge id index
        imageIndex: 0,
        categorizedPhotos: null,
        isOjOBrokerage: null,
        houseRealStatus: null,
        houseRealStatusDisplay: null,
        labelClass: null,
        labelName: null,
        isCommingSoonListing: false,
        propertyType: null,
        propertyTypeDisplay: null,
        propertyTypeIcon: null,
        isSold: null,
        isActive: null,
        isNew: null,
        isPrOnly: null,
        isPriceReduced: null,
        isLand: null,
        isCondo: null,
        bath: null,
        bed: null,
        fCooling: null,
        fHeating: null,
        fLevels: null,
        garage: null,
        daysOnMovoto: null,
        price: null,
        isEstPrice: false,
        distance: null,
        area: null,
        areaUnit: null,
        pricePerArea: null,
        lotSize: null,
        lotSizeUnit: null,
        sqftTotal: null,
        buildingArea: null,
        listDate: null,
        soldDate: null,
        soldTimeDisplay: null,
        createdAt: null,
        updateAt: null,
        createTimeDisplay: null,
        listPrice: null,
        priceEstValue: null,
        estPrice: null,
        estPricePerArea: null,
        estPriceRange: null,
        permitAvm: null,
        hiddenMode: null,
        closePrice: null,
        priceChange: null,
        lastListPrice: null,
        priceChangedDate: null,
        isCheapProperty: null,
        openHouses: null,
        openDate: null,
        isFavorite: null,
        listingByMovoto: null,
        mlsDbNumber: null,
        mlsId: null,
        mlsNumber: null,
        formatMlsInfo: null,
        mlsLogoSrc: null,
        mlsDescription: null,
        mls: {
            id: null,
            mlsName: null,
            hasLogo: null,
        },
        brokerageDetails: null,
        addressId: null,
        updatedStatus: {
            lastUpdate: null,
            lastChecked: null,
        },
        officeListName: null,
        complianceListOffice: null,
        listingOfficeDescription: null,
        yearBuilt: null,
        hoafee: null,
        show3DTour: null,
        virtualLink: null,
        threeDTourLink: null,
        is3DTour: null,
        isVideoTour: null,
        videoTourLink: null,
        features: null,
        mortgagePerMonth: null,
        monthTotal: null,
        activeUnderContract: false,
        nearbyHomes: [],
        nearbyRentHomes: [],
        nearbyNHSHomes: [],
        preferredAgentForDisplay: null,
        dppAgent: null,
        listingAgent: null,
        listingAgentData: null,
        nearbySchools: [],
        nearbySchoolAvgRating: null,
        schoolDistricts: [],
        publicRecord: null,
        priceHistory: [],
        hideIsViewed: false,
        marketTrendTable: null,
        localHighlight: null,
        localHighlightScore: null, //below are async data
        market1Y: null,
        market5Y: null,
        forecast: null,
        nearbySaleForLand: [],
        nearbySoldForLand: [],
        nearbySoldHomes: [],
        nearbyPois: [],
        cityArticles: [],
        nearbyCityListing: [],
        seoNearbyCity: [],
        seoNearbyZipCode: [],
        seoNearbyNeighborhood: [],
        seoNearbyCounty: [],
        weatherSummary: [],
        imageFormat: 'jpeg',
        imageDownloaderStatus: 0,
        htmlDescription: '',
        agentDetails: null,
        hidePhotosByCompliance: false,
        avgPendingDays: 0,
        avgPendingCount: 0,
        mapUrls: [],
        affordMonthPay: 0,
        affordTotal: 0,
        avmInfo: null,
    };
}
