export function populateStoreForPageType(store, app) {
    const molangFun = app.config.globalProperties.molang.bind(app);
    const glb = store.state.glb;
    const pageData = glb.pageData;
    const pageType = glb.pageType;
    glb.pageData = null;
    store.state.glb = glb;
    store.state.molang = molangFun;

    if (pageData?.compliance) {
        store.commit('glb/updateCompliance', pageData.compliance);
    }

    if (pageType === 'vmapsearch') {
        store.commit('msp/updateMapSearchGeo', { data: pageData, glb: glb });
        if (pageData.marketSummary) {
            store.commit('glb/updateMarketSummary', { key: store.state.msp.geo.cityPath, value: { midPrice: pageData.marketSummary.medianPricePerSqft } });
        }
        store.commit('msp/updateViewStatus', pageData.currentViewStatus);
        store.commit('msp/updateMapSearchState', { data: pageData, glb: glb, molang: app.config.globalProperties.molang.bind(app) });
        store.commit('glb/updateListingCount', pageData.listingCountObj);
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: store.state.msp.geo });
    } else if (pageType === 'vbrokerage') {
        store.commit('agents/updateBrokerage', {
            data: pageData,
            glb: glb,
        });
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: store.state.agents.geo });
    } else if (pageType.indexOf('vagentsearch') > -1) {
        store.commit('agents/updateAgentSearch', {
            data: pageData,
            glb: glb,
        });
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: store.state.agents.geo });
    } else if (pageType === 'vdap') {
        store.commit('dap/updateAgent', {
            data: pageData,
            glb: glb,
        });
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: store.state.dap.geo });
    } else if (pageType === 'vdpp') {
        store.commit('dpp/updateProperty', {
            data: pageData,
            glb: glb,
        });
        if (pageData.cityMarketSnapshot && pageData.cityMarketSnapshot.one_month_ago && pageData.cityMarketSnapshot.one_month_ago.singleTypeMedianPricePerSqftHouse) {
            store.commit('glb/updateMarketSummary', { key: store.state.dpp.geo.cityPath, value: { midPrice: pageData.cityMarketSnapshot.one_month_ago.singleTypeMedianPricePerSqftHouse } });
        }
        store.commit('glb/updateListingCount', pageData.listingCountObj);
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: store.state.dpp.geo, propertyTypes: [store.state.dpp.propertyType] });
    } else if (pageType === 'vhome') {
        store.commit('home/updateHome', {
            data: pageData,
            glb: glb,
        });
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: store.state.home.geo });
    } else if (/^vguide/.test(pageType)) {
        store.commit('guide/updateGuide', {
            data: pageData,
            glb: glb,
        });
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: store.state.guide.geo });
    } else if (/^vsitemap/.test(pageType)) {
        glb.pageData = pageData;
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: store.state.sitemap.geo });
    } else if (/^vschools/.test(pageType) || /^vmarket-trends/.test(pageType)) {
        glb.pageData = pageData;
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: pageData.geo });
    } else if (/^vresetpassword/.test(pageType) || /^vactivateaccount/.test(pageType) || pageType === 'vmlslogo') {
        glb.pageData = pageData;
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: glb.pageData.geo });
    } else if (pageType === 'vdsp') {
        store.commit('dsp/updateDsp', {
            data: pageData,
            glb: glb,
        });
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: store.state.dsp.geo });
    } else if (pageType === 'vabout') {
        store.commit('about/updateAbout', {
            data: pageData,
            glb: glb,
        });
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: store.state.about.geo });
    } else if (pageType === 'vsell_cities') {
        store.commit('sell/updateSellCity', {
            data: pageData,
            glb: glb,
        });
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: store.state.sell.geo });
    } else if (pageType === 'vconsent') {
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: { hasGeoInfo: false } });
    } else if (pageType === 'vsurvey') {
        store.commit('survey/initalSurvey', pageData);
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: pageData.geo });
    } else if (pageType === 'vsurveymortgage') {
        store.commit('surveyMortgage/initalSurveyMortgage', pageData);
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: pageData.geo });
    } else if (/^vmymovoto/.test(pageType)) {
        store.commit('mymovoto/updateMymovoto', {
            data: pageData,
            glb: glb,
        });
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: pageData.geo });
    } else if (/^verror/.test(pageType) || /^vunsubscribe/.test(pageType) || pageType === 'vcapolicyview') {
        glb.pageData = pageData;
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: glb.geo });
    } else if (pageType === 'vwidgethomefinder' || pageType === 'vwidgethearsthomefinder') {
        glb.pageData = pageData;
    } else if (pageType === 'vgeolanding') {
        store.commit('geolanding/updateGeoLanding', pageData);
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: store.state.geolanding.geo });
    } else if (pageType === 'vsellfast') {
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: { hasGeoInfo: false } });
    } else if (pageType === 'vneighborhood') {
        store.commit('neighborhood/updateNeighborhood', { pageData, glb, molang: molangFun });
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: pageData.geo });
    } else {
        if (pageData && pageData.geo) {
            glb.geo = pageData.geo;
        }
        store.commit('glb/updateMenu', { glb, molang: molangFun, geo: glb.geo });
    }
}

export function registerWarnHandler(context, app) {
    app.config.warnHandler = (msg, instance, trace) => {
        if (context.enableWarnLog || context.splits?.['movoto-throttle-settings-CW-2219'] === 'on') {
            console.warn(`[Vue warn] ${msg} ${trace ? trace.split('\n').join(' ') : ''}`);
        }
    };
}
